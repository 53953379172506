export default {
  login: login,
  getSensorByGroup: getSensorByGroup,
  getSensorOne: getSensorOne,
  getSensorHistory: getSensorHistory,
  updateSensorCoordinate: updateSensorCoordinate,
}

import axios from 'axios'
axios.defaults.baseURL = '/api';


//登陆
function login(data, success, error) {
  var resUrl = '/webuser/login' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取设备传感器
function getSensorByGroup(data, success, error) {
  var resUrl = '/screen/getSensorByGroup' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取传感器数据
function getSensorOne(data, success, error) {
  var resUrl = '/basestationsensor/getOne' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取传感器运行记录
function getSensorHistory(data, success, error) {
  var resUrl = '/basestationhistory/getHourList' + data;
  // console.log(resUrl);
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

/**
 * 更新传感器坐标
 * @param {*} data 
 * @param {*} success 
 * @param {*} error 
 */
function updateSensorCoordinate(data, success, error) {
  var resUrl = '/basestationsensor/updateCoordinateList';
  axios({
    method: 'post',
    url: resUrl,
    data: data,
    headers: {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}
