import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    curUserName: '',
    globalData: {
      loginToken: '',
      navBarShow: 1,
      quitBtnValid: 1,
      layoutMapImg: '',  //布局图名称
      province: '',
      region: '',
      longitude: null,
      latitude: null,
      regionList: null,
      curGroupName: '',
      curGroupId: null,
      curMachineId: null,
      curOnline: 0,  //在线状态，1：在线，0：不在线
      curSensorType: '',
      curSensorNum: '',
      sensorLoop: true,  //循环展示，false：否，true：是
      coordinateX: null,  //当前设备坐标X
      coordinateY: null,  //当前设备坐标Y
      sensorCoordinateArr: null,   //当前设备传感器 坐标数组

    }
  },
  mutations: {
    setCurUserName(state, data) {
      state.curUserName = data;
    },
    setGlobalData(state, data) {
      state.globalData = data;
    },
    setCurGroupName(state, data) {
      state.globalData.curGroupName = data;
    },
    setCurMachineId(state, data) {
      state.globalData.curMachineId = data;
    },
    setCurOnline(state, data) {
      state.globalData.curOnline = data;
    },
    setCurSensorType(state, data) {
      state.globalData.curSensorType = data;
    },
    setCurSensorNum(state, data) {
      state.globalData.curSensorNum = data;
    },
    setSensorLoop(state, data) {
      state.globalData.sensorLoop = data;
    },
    setCoordinateX(state, data) {
      state.globalData.coordinateX = data;
    },
    setCoordinateY(state, data) {
      state.globalData.coordinateY = data;
    },
    setSensorCoordinateArr(state, data) {
      state.globalData.sensorCoordinateArr = data;
    }
  },
  actions: {
    updateCurUserName(context,data){
      context.commit('setCurUserName',data);
    },
    updateCurGroupName(context,data){
      context.commit('setCurGroupName',data);
    }
  },
  modules: {
  },
  //把数据存储到本地 localStorage
  plugins: [createPersistedState()],
  //把数据存储到本地 session
  // plugins: [createPersistedState({
  //   storge: window.sessionStorage
  // })],
})

export default store;
