import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';

// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import modal from './components/modal/index';
import Toast from './components/toast';

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

//httpApi.js 引用axios
// import axios from 'axios'
// axios.defaults.baseURL = '/api';
// //http
// Vue.prototype.$http = axios;
//引入api接口
import httpApi from './utils/httpApi.js'

import global from './config/global.js'


// 全局注册
Vue.prototype.$httpApi = httpApi;
Vue.prototype.$GLOBAL = global
Vue.prototype.$Toast = Toast
Vue.use(modal)

Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
