import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '登录界面'
    }
  },
  {
    path: '/login',
    redirect: '/'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    // component: () => import('@/views/login.vue'),
    beforeEnter: (to, from, next) => {
      // // store.state.globalData.curGroupName = to.query.nanme;
      // store.dispatch('updateCurGroupName', to.query.name);
      // next();
      // store.dispatch('updateNoPwdLogin', 1);
      // store.dispatch('updateUserName', to.query.name);
      
      store.dispatch('updateCurUserName', to.query.name);
      next();
      
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/index.vue'),
    meta: {
      title: '上蔬源可视化平台',
      requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
    beforeEnter: (to, from, next) => {
      
      if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
        if (store.state.globalData.loginToken) {  // 通过vuex state获取当前的token是否存在
            next();
        }
        else {
            next({
                path: '/login',
                // query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
      }
      else {
          next();
      }
    },
  },
  

]
const router = new VueRouter({
  routes
})

export default router