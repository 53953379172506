

//页面数据刷新时间
//重新获取设备列表
var deviceRefreshTiming = 60 * 60 * 1000; //60 * 1000;
//设备列表轮训
var deviceShowTiming = 40 * 1000; //60 * 1000;

//布局图文件地址
var layoutMapPath = "http://bs.shangshuyuan.net.cn/layoutMap/";

export default {
    //页面数据刷新时间
    deviceRefreshTiming,
    deviceShowTiming,
    layoutMapPath,
}
